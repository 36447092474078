/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { useEffect, useRef } from 'react';

const useScreenWakeLock = () => {
  const wakeLockRef = useRef(null);

  useEffect(() => {
    const isSupported = 'wakeLock' in navigator;

    const requestWakeLock = async () => {
      try {
        if (isSupported && !wakeLockRef.current) {
          wakeLockRef.current = await navigator.wakeLock.request('screen');
        }
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        requestWakeLock();
      } else {
        if (wakeLockRef.current) {
          wakeLockRef.current.release();
          wakeLockRef.current = null;
        }
      }
    };

    const handleUserInteraction = () => {
      requestWakeLock();
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };

    if (isSupported) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      document.addEventListener('click', handleUserInteraction);
      document.addEventListener('touchstart', handleUserInteraction);
    }

    return () => {
      if (isSupported) {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        document.removeEventListener('click', handleUserInteraction);
        document.removeEventListener('touchstart', handleUserInteraction);
        if (wakeLockRef.current) {
          wakeLockRef.current.release();
          wakeLockRef.current = null;
        }
      }
    };
  }, []);
};

export default useScreenWakeLock;
