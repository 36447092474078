/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';
import {Navigate} from 'react-router-dom';

const RequireUuid = ({uuid, children}) => {
  if (uuid) {
    return children;
  } else {
    return <Navigate to='/connect' replace />;
  }
};

export default RequireUuid;
