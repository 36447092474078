/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';

const MessagingServiceContext = createContext();

const defaultBrowserFeatures = {
  cpu: false,
  ram: false,
  network: false,
  panic: false,
  sound: false,
  tabs: 0,
};

export const ConnectionStatus = {
  Connecting: 'Connecting',
  Connected: 'Connected',
  Disconnected: 'Disconnected',
  Rejected: 'Rejected',
  Unreachable: 'Unreachable',
};

export const MessagingServiceProvider = ({children, uuid, setUuid}) => {
  const [browserFeatures, setBrowserFeatures] = useState(
    defaultBrowserFeatures,
  );
  const [connectionStatus, setConnectionStatus] = useState(
    ConnectionStatus.Disconnected,
  );
  const socketRef = useRef(null);

  const updateFeatureState = (featureKey, value) => {
    setBrowserFeatures(prevState => ({
      ...prevState,
      [featureKey]: value,
    }));
  };

  const sendPong = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({type: 'pong'}));
    }
  };

  useEffect(() => {
    if (!uuid) {
      setConnectionStatus(ConnectionStatus.Disconnected);
      return;
    }

    const server = `${process.env.REACT_APP_WEBSOCKET_SERVER}`;
    const socket = new WebSocket(server);
    socketRef.current = socket;

    socket.onopen = () => {
      if (uuid) {
        const payload = {
          uuid: uuid,
          clientType: 'page',
          targetType: 'extension',
          type: 'register',
        };
        socket.send(JSON.stringify(payload));
        setConnectionStatus(ConnectionStatus.Connecting);

        setTimeout(() => {
          setConnectionStatus(prevStatus => {
            if (prevStatus === ConnectionStatus.Connecting) {
              return ConnectionStatus.Disconnected;
            }
            return prevStatus;
          });
        }, 5000);
      }
    };

    socket.onmessage = event => {
      processMessage(JSON.parse(event.data));
    };

    socket.onclose = () => {
      if (connectionStatus !== ConnectionStatus.Disconnected) {
        setConnectionStatus(ConnectionStatus.Unreachable);
      }
    };

    socket.onerror = error => {
      console.log('WebSocket error:', error);
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [uuid]);

  const processMessage = msg => {
    if (!msg.type) {
      return;
    }

    if (msg.type === 'ping') {
      sendPong();
      return;
    }

    if (msg.type === 'update' && msg.components) {
      setConnectionStatus(ConnectionStatus.Connected);
      setBrowserFeatures(prevState => ({
        ...prevState,
        ...msg.components,
      }));
      return;
    }

    if (msg.type === 'connected' && msg.value === 'false') {
      setConnectionStatus(ConnectionStatus.Disconnected);
      setBrowserFeatures(defaultBrowserFeatures);
    }

    if (msg.type === 'error' && msg.value === 'rejected') {
      localStorage.removeItem('uuid');
      setUuid(null);
      setConnectionStatus(ConnectionStatus.Disconnected);
    }
  };

  const sendMessageToExtension = message => {
    if (
      socketRef.current &&
      socketRef.current.readyState === WebSocket.OPEN &&
      uuid &&
      connectionStatus === ConnectionStatus.Connected
    ) {
      const payload = {
        uuid: uuid,
        clientType: 'page',
        targetType: 'extension',
        message: message,
      };
      socketRef.current.send(JSON.stringify(payload));
    }
  };

  return (
    <MessagingServiceContext.Provider
      value={{
        browserFeatures,
        updateFeatureState,
        connectionStatus,
        sendMessageToExtension,
      }}
    >
      {children}
    </MessagingServiceContext.Provider>
  );
};

export const useMessagingService = () => {
  return useContext(MessagingServiceContext);
};
