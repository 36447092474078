/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React, { useState, useEffect } from "react";
import { useMessagingService } from "./MessagingServiceContext";

import styles from "./components/Tile.module.css";
import TileHeader from "./components/TileHeader";
import Toggle from "./components/Toggle";

import headerIcon from "./img/limiterTile/ramLimiterTitle.svg";
import mainDisabledIcon from "./img/limiterTile/ramLimiterDisabledMain.svg";
import mainEnabledIcon from "./img/limiterTile/ramLimiterEnabledMain.svg";

import FeatureUpdateMessage from "./Messages";

const RamLimiterTile = ( props ) => {
  const title = "RAM LIMITER";
  const { updateFeatureState, sendMessageToExtension } = useMessagingService();
  const [enabled, setEnabled] = useState(props.enabled);

  useEffect(() => {
    setEnabled(props.enabled);
  }, [props.enabled]);

  const handleClick = () => {
    const newEnabled = !enabled;
    updateFeatureState("ram", newEnabled);
    if (navigator.vibrate) {
      navigator.vibrate(20);
    }
    const message = new FeatureUpdateMessage("ram-limiter", newEnabled );
    sendMessageToExtension(message);
  };

  return (
    <div className={`${styles.tile} ${enabled ? styles.enabled : ""}`}>
      <TileHeader title={title} icon={headerIcon} />
      <div className={styles.main}>
        <img
          className={styles.mainIcon}
          src={`${enabled ? mainEnabledIcon : mainDisabledIcon}`}
          alt={`${title} Icon`}
        />
      </div>
      <div className={styles.bottom}>
        <div className={styles.status}>
          {enabled ? "ENABLED" : "DISABLED"}
        </div>
        <Toggle checked={enabled} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

export default RamLimiterTile;
