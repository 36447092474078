/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import cpuLimiterIcon from "./img/limiterTile/cpuLimiterTitle.svg";
import ramLimiterIcon from "./img/limiterTile/ramLimiterTitle.svg";
import networkLimiterIcon from "./img/limiterTile/networkLimiterTitle.svg";
import panicButtonIcon from "./img/panicButtonTitle.svg";
import tabSlasherIcon from "./img/tabSlasherTitle.svg";
import soundsIcon from "./img/soundsTitle.svg";

const SettingsItems = {
    cpu: { id: "cpu", label: "CPU Limiter", icon: cpuLimiterIcon },
    ram: { id: "ram", label: "RAM Limiter", icon: ramLimiterIcon },
    network: {
      id: "network",
      label: "Network Limiter",
      icon: networkLimiterIcon,
    },
    panic: { id: "panic", label: "Panic Button", icon: panicButtonIcon },
    sound: { id: "sound", label: "Sound and Music", icon: soundsIcon },
    tabs: { id: "tabs", label: "Tab Slasher", icon: tabSlasherIcon },
};

export default SettingsItems;
