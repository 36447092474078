/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React, { useState, useEffect } from "react";
import { useMessagingService } from "./MessagingServiceContext";

import Button from "./components/Button";
import TileHeader from "./components/TileHeader";
import Toggle from "./components/Toggle";

import styles from "./components/Tile.module.css";

import headerIcon from "./img/panicButtonTitle.svg";

import FeatureUpdateMessage from "./Messages";

const PanicButtonTile = ( props ) => {
  const title = "PANIC BUTTON";
  const { updateFeatureState, sendMessageToExtension } = useMessagingService();
  const [armed, setArmed] = useState(() => {
    const storedValue = sessionStorage.getItem('PanicButtonTileArmed');
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    sessionStorage.setItem("PanicButtonTileArmed", armed);
  }, [armed]);

  const handleArmedClick = () => {
    if (navigator.vibrate) {
      navigator.vibrate(20);
    }
    setArmed(!armed);
  }

  const handlePush = () => {
    const newEnabled = !props.enabled;
    updateFeatureState("panic", newEnabled);
    if (navigator.vibrate) {
      navigator.vibrate(40);
    }
    const message = new FeatureUpdateMessage("panic-button");
    sendMessageToExtension(message);
  };

  return (
    <div className={`${styles.tile} ${props.enabled ? styles.enabled : ""}`}>
      <TileHeader title={title} icon={headerIcon} />
      <div className={styles.main}>
        <Button onClick={handlePush} disabled={!armed}>
          PUSH
        </Button>
      </div>
      <div className={styles.bottom}>
        <div className={styles.status}>{armed ? "ARMED" : "DISARMED"}</div>
        <Toggle checked={armed} onClick={handleArmedClick} />
      </div>
    </div>
  );
};

export default PanicButtonTile;
