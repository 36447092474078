/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React, {useEffect, useState} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useSearchParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import styles from './App.css';
import SettingsPage from './SettingsPage';
import HomePage from './HomePage';
import ConnectPage from './ConnectPage';
import {SettingsProvider} from './SettingsContext';
import {MessagingServiceProvider} from './MessagingServiceContext';
import RequireUuid from './RequireUuid';
import NoUuidOnly from './NoUuidOnly';
import LegalPage from './LegalPage';

const App = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [uuid, setUuid] = useState(() => {
    const paramUuid = searchParams.get('uuid');
    if (paramUuid) {
      return paramUuid;
    }
    return localStorage.getItem('uuid') || null;
  });

  useEffect(() => {
    const paramUuid = searchParams.get('uuid');
    if (paramUuid && paramUuid !== uuid) {
      setUuid(paramUuid);
      navigate(location.pathname, {replace: true});
    }
  }, [searchParams, setUuid, navigate]);

  useEffect(() => {
    if (uuid) {
      localStorage.setItem('uuid', uuid);
    } else {
      localStorage.removeItem('uuid');
      if (location.pathname !== '/connect') {
        navigate('/connect', {replace: true});
      }
    }
  }, [uuid, navigate, location.pathname]);

  return (
    <MessagingServiceProvider uuid={uuid} setUuid={setUuid}>
      <div className={styles.appContainer}>
        <Routes>
          <Route
            path='/connect'
            element={
              <NoUuidOnly uuid={uuid}>
                <ConnectPage setUuid={setUuid} />
              </NoUuidOnly>
            }
          />
          <Route
            path='/settings'
            element={
              <RequireUuid uuid={uuid}>
                <SettingsPage />
              </RequireUuid>
            }
          />
          <Route
            path='/legal'
            element={
              <RequireUuid uuid={uuid}>
                <LegalPage />
              </RequireUuid>
            }
          />
          <Route
            path='/'
            element={
              <RequireUuid uuid={uuid}>
                <HomePage />
              </RequireUuid>
            }
          />
          <Route
            path='*'
            element={
              uuid ? (
                <Navigate to='/' replace />
              ) : (
                <Navigate to='/connect' replace />
              )
            }
          />
        </Routes>
      </div>
    </MessagingServiceProvider>
  );
};

const RootApp = () => {
  return (
    <SettingsProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SettingsProvider>
  );
};

export default RootApp;
