/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React, { useState, useEffect } from "react";
import styles from "./TabSlasherTile.module.css";
import { useMessagingService } from "./MessagingServiceContext";

import Button from "./components/Button";
import TileHeader from "./components/TileHeader";

import tileStyles from "./components/Tile.module.css";

import headerIcon from "./img/tabSlasherTitle.svg";
import mainIcon from "./img/tabSlasherMain.svg";

import FeatureUpdateMessage from "./Messages";

const TabSlasherTile = (props) => {
  const title = "TAB SLASHER";
  const { sendMessageToExtension } = useMessagingService();
  const [openedTabs, setOpenedTabs] = useState(props.tabs);

  useEffect(() => {
    setOpenedTabs(props.tabs);
  }, [props.tabs]);

  const handleKillAll = () => {
    if (navigator.vibrate) {
      navigator.vibrate(40);
    }
    if (openedTabs === 0) {
      return;
    }
    const message = new FeatureUpdateMessage("tab-slasher");
    sendMessageToExtension(message);
  };

  return (
    <div className={tileStyles.tile}>
      <TileHeader title={title} icon={headerIcon} />
      <div className={tileStyles.main}>
        <div className={styles.info}>
          <img
            className={styles.mainIcon}
            src={mainIcon}
            alt={`${title} Icon`}
          />
          <div className={styles.tabsCount}>{openedTabs}</div>
        </div>
        <div className={styles.label}>OPENED TABS</div>
      </div>
      <div className={tileStyles.bottom}>
        <Button onClick={handleKillAll}>KILL'EM ALL</Button>
      </div>
    </div>
  );
};

export default TabSlasherTile;
