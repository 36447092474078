/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';
import {Link} from 'react-router-dom';
import styles from './Subpage.module.css';

import backIcon from '../img/back.svg';

const TopBar = ({title}) => {
  return (
    <div className={styles.topBar}>
      <Link to='..'>
        <img className={styles.backIcon} alt='Back' src={backIcon} />
      </Link>
      <div className={styles.topBarTitle}>{title}</div>
    </div>
  );
};

const Subpage = ({title, children}) => {
  return (
    <div className={styles.page}>
      <TopBar title={title} />
      <div className={styles.panel}>{children}</div>
    </div>
  );
};

export default Subpage;
