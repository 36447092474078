/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from "react";
import { useMessagingService } from "./MessagingServiceContext";

import TileHeader from "./components/TileHeader";
import Toggle from "./components/Toggle";

import tileStyles from "./components/Tile.module.css";

import headerIcon from "./img/soundsTitle.svg";
import mainDisabledIcon from "./img/soundsDisabledMain.svg";
import mainEnabledIcon from "./img/soundsEnabledMain.svg";

import FeatureUpdateMessage from "./Messages";

const SoundsTile = ( props ) => {
  const title = "SOUNDS AND MUSIC";
  const { updateFeatureState, sendMessageToExtension } = useMessagingService();

  const handleClick = () => {
    const newMuted = !props.muted;
    updateFeatureState("sound", newMuted);
    if (navigator.vibrate) {
      navigator.vibrate(20);
    }
    const message = new FeatureUpdateMessage("sound", newMuted);
    sendMessageToExtension(message);
  };

  return (
    <div className={`${tileStyles.tile} ${props.muted ? tileStyles.enabled : ""}`}>
      <TileHeader title={title} icon={headerIcon} />
      <div className={tileStyles.main}>
        <img
          className={tileStyles.mainIcon}
          src={`${props.muted ? mainEnabledIcon : mainDisabledIcon}`}
          alt={`${title} Icon`}
        />
      </div>
      <div className={tileStyles.bottom}>
        <div className={tileStyles.status}>{props.muted ? "MUTED" : "UNMUTED"}</div>
        <Toggle checked={props.muted} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

export default SoundsTile;
