/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';
import {Navigate} from 'react-router-dom';

const NoUuidOnly = ({uuid, children}) => {
  if (!uuid) {
    return children;
  } else {
    return <Navigate to='/' replace />;
  }
};

export default NoUuidOnly;

