/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import React from 'react';
import {Link} from 'react-router-dom';
import styles from './components/TopBar.module.css';
import {useMessagingService, ConnectionStatus} from './MessagingServiceContext';

import logo from './img/logo.svg';
import settings from './img/settings.svg';
import connectedIcon from './img/connected.svg';
import connectingIcon from './img/connecting.svg';
import disconnectedIcon from './img/disconnected.svg';
import unreachableIcon from './img/warning.svg';

const StatusTopBar = ({withSettings = true}) => {
  const {connectionStatus} = useMessagingService();

  let statusText = 'Disconnected';
  let statusIcon = disconnectedIcon;

  if (connectionStatus === ConnectionStatus.Connected) {
    statusText = 'Connected';
    statusIcon = connectedIcon;
  } else if (connectionStatus === ConnectionStatus.Unreachable) {
    statusText = 'Unreachable';
    statusIcon = unreachableIcon;
  } else if (connectionStatus === ConnectionStatus.Connecting) {
    statusText = 'Connecting';
    statusIcon = connectingIcon;
  }

  return (
    <div className={styles.topBar}>
      <img className={styles.logo} src={logo} alt='Logo' />
      <div className={styles.title}>GX Connect</div>
      <div className={styles.statusContainer}>
        <div className={styles.status}>{statusText}</div>
        {statusIcon && (
          <img
            className={styles.statusIcon}
            src={statusIcon}
            alt={statusText}
          />
        )}
      </div>
      {withSettings && (
        <Link to='/settings' className={styles.settingsLink}>
          <img className={styles.settingsIcon} src={settings} alt='Settings' />
        </Link>
      )}
    </div>
  );
};

export default StatusTopBar;
